<script setup lang="ts">
import { onMounted, ref } from 'vue';

defineProps<{ placeholder?: string }>();

const model = defineModel<string>({ required: true });

const input = ref<HTMLInputElement | null>(null);

onMounted(() => {
  if (input.value?.hasAttribute('autofocus')) {
    input.value?.focus();
  }
});

defineExpose({ focus: () => input.value?.focus() });
</script>

<template>
  <input class="focus:ring-2 focus:ring-green-600 border-none rounded-md shadow-sm" v-model="model" ref="input" :placeholder="placeholder" />
</template>
